import { get } from 'lodash';
import { formatUSD } from 'utils/format';

export default {
	cityLandingPage: (city, state, avgPrices) => {
		const overallAvgPrice = get(avgPrices, 'overall.price');
		const avg5x5Price = get(avgPrices, '5x5.price');
		const avg10x10Price = get(avgPrices, '10x10.price');
		const questions = [
			{
				question: `### What size storage unit do I need in ${city}, ${state}?`,
				answer: `Typically, a 10'x10' storage unit will comfortably fit a one-bedroom home in ${city}, ${state}. This unit is the size of a small garage. For a 2 to 3 bedroom house, consider a larger 10'x20' storage unit.`
			}
		];

		if (overallAvgPrice) {
			questions.push({
				question: `### How much does a storage unit cost in ${city}?`,
				answer: `The average cost of a storage unit in ${city} is ${formatUSD(overallAvgPrice)}.
				You can compare prices and find the best storage unit for you on Sparefoot.`
			});
		}

		questions.push({
			question: `### Are there 1st month free storage deals in ${city}?`,
			answer: `Many facilities do offer a first month free promotion for new reservations.
			To find storage units in ${city} with a first month free deal you can search
			by zip code or city on Sparefoot.com.`
		});

		if (avg5x5Price) {
			questions.push({
				question: `### How much are small storage units in ${city}?`,
				answer: `A small storage unit is usually considered a 5' x 5' unit and costs on average
				${formatUSD(avg5x5Price)} per month in ${city}. You can compare prices for all 5' x 5' storage units
				in ${city} on Sparefoot.com.`
			});
		}

		if (avg10x10Price) {
			questions.push({
				question: `### How much do 10x10 storage units cost in ${city}?`,
				answer: `In ${city} a 10' x 10' storage unit costs on average ${formatUSD(avg10x10Price)} per month.
				You can compare prices for all 10' x 10' storage units in ${city} on Sparefoot.`
			});
		}

		questions.push({
			question: `### Do I need climate controlled storage in ${city}, ${state}?`,
			answer: `Climate controlled storage, also called temperature controlled, maintains a year-round temperature between 55 to 85 degrees. This helps protect items that might be sensitive to extreme temperatures or excessive humidity. If you're planning to store valuable or sentimental items in ${city}, a storage unit with climate control might be worthwhile.`
		}, {
			question: `### What is the advantage of drive-up storage units in ${city}, ${state}?`,
			answer: 'Drive-up access feels more like having a personal garage for storage - especially when ' +
				'loading heavy furniture or oversized items. ' +
				'A storage unit with drive-up access allows you to pull your car or moving truck directly up ' +
				'to the door of the unit, but this is only an option for outdoor storage units.'
		});

		return questions;
	},
	vehicleLandingPage: {
		car: (city, state, avgPrices) => {
			const overallAvgPrice = get(avgPrices, 'overall.price');
			const avg20Price = get(avgPrices, '20.price');
			const questions = [];

			if (overallAvgPrice) {
				questions.push({
					question: `### How much does it cost to store a car in ${city}?`,
					answer: `The average cost of car storage in ${city} is ${formatUSD(overallAvgPrice)} per month. You can
					compare prices and find the best storage unit for you Sparefoot.com`
				});
			}

			questions.push({
				question: `### Are there car storage discounts in ${city}?`,
				answer: `Many storage facilities do offer car storage discounts for new reservations. To find vehicle
					storage in ${city} with a discount you can search by zip code or city on Sparefoot.`
			});

			if (avg20Price) {
				questions.push({
					question: `### How much is a 20' long storage unit in ${city}?`,
					answer: `An average 20' long storage unit in ${city} is ${formatUSD(avg20Price)} per month. Some
					compact cars may fit in a '15 long storage unit, but a 10x20 is a better option because it is big enough for most cars.`
				});
			}

			if (overallAvgPrice) {
				questions.push({
					question: `### How much does outdoor car storage cost in ${city}?`,
					answer: `Outdoor car storage costs about ${formatUSD(overallAvgPrice)} per month in ${city}.
					Typically, outdoor parking spots are cheaper because they don't offer the same level of protection as
					an indoor or enclosed storage unit.`
				});
			}

			return questions;
		},
		rv: (city, state, avgPrices) => {
			const avg30Price = get(avgPrices, '30.price');
			const questions = [];

			if (avg30Price) {
				questions.push({
					question: `### What does it cost to store an RV in ${city}?`,
					answer: `On average it costs ${formatUSD(avg30Price)} per month to store an RV in ${city}. You can compare
					RV storage prices and browse discounts on Sparefoot.com.`
				});
			}

			questions.push({
				question: `### Where can I store my RV long term in ${city}?`,
				answer: `If you don't have a garage large enough, the best place to store an RV long term is at a storage
				facility. If you're worried about potential damage, indoor RV storage is the best option. You can compare
				storage prices in ${city} on Sparefoot.`
			},
			{
				question: `How do I find the cheapest RV storage in ${city}?`,
				answer: `On Sparefoot.com you can compare all RV storage prices and facilities in ${city} to find the cheapest
				RV storage option.`
			},
			{
				question: `### Is indoor RV storage in ${city} more expensive?`,
				answer: `Because indoor and enclosed RV storage provide the most protection, the monthly cost in ${city}
				will likely be a little higher than an outdoor parking space.`
			});

			return questions;
		},
		boat: (city, state, avgPrices) => {
			const avg25Price = get(avgPrices, '25.price');
			const questions = [];

			if (avg25Price) {
				questions.push({
					question: `### How much does boat storage cost in ${city}?`,
					answer: `Most boats can fit in either a 10'x20' or 10'x30' storage unit, and on average these unit sizes
					cost ${formatUSD(avg25Price)} per month in ${city}.`
				});
			}

			questions.push({
				question: `### Where can I store my boat in ${city}?`,
				answer: `If you want easy access to your boat, look for a marina slip or an outdoor parking space. If you're
				looking to store your boat long term the best option is at a storage facility that offers indoor boat storage,
				and you can browse all storage options in ${city} on Sparefoot.com.`
			}, {
				question: `### Will storing my boat outdoors in ${city} cause damage?`,
				answer: `If you're storing your boat long term, you might consider an indoor or enclosed storage unit to
				prevent any damage. But, if you want easy access to your boat, or plan to use it a lot, a boat slip or parking
				space at a marina is the best option.`
			}, {
				question: `### How do I find cheap boat storage in ${city}?`,
				answer: `Many storage facilities offer discounts, like first month free, for new customers. You can browse
				discounts and prices for all storage options in ${city} on Sparefoot.`
			});

			return questions;
		}
	},
	amenityLandingPage: {
		'climate-controlled': (city, state, avgPrices) => {
			const overallAvgPrice = get(avgPrices, 'overall.price');
			const questions = [];
			if (overallAvgPrice) {
				questions.push({
					question: `### How much does a climate controlled storage cost in ${city}?`,
					answer: `The average cost of climate controlled storage in ${city} is ${formatUSD(overallAvgPrice)} per month.
					You can compare prices and find the best storage unit for you on Sparefoot.com.`
				});
			}
			questions.push({
				question: `### Do I need climate controlled storage in ${city}?`,
				answer: `Climate controlled storage is recommended if you live in an area with extreme temperatures or humidity
				and are storing items that are especially sensitive, like mattresses, books or photos, clothing, and any sentimental items.`
			}, {
				question: `### Are there discounts for climate controlled storage in ${city}?`,
				answer: `Most storage facilities do offer discounts for climate controlled storage. To find discounts in
				${city} you can search by zip code or city on Sparefoot.`
			});

			return questions;
		},
		'24-hour-access': (city, state, avgPrices) => {
			const questions = [];
			const overallAvgPrice = get(avgPrices, 'overall.price');
			const avg5x10 = get(avgPrices, '5x10.price');

			if (overallAvgPrice) {
				questions.push({
					question: `### What is the cost of 24 hour access storage in ${city}?`,
					answer: `A 24 hour access storage unit costs about ${formatUSD(overallAvgPrice)} per month in ${city}, and you can compare all sizes and prices on Sparefoot.com.`
				});
			}

			if (avg5x10) {
				questions.push({
					question: `### How much does a 5x10 storage unit cost in ${city}?`,
					answer: `The average cost of a 5' x 10' storage unit in ${city} costs about ${formatUSD(avg5x10)} per month, and is typically the most popular sized unit. You can compare prices for all 5' x 10' storage units in ${city} on Sparefoot.`
				});
			}

			questions.push({
				question: `### How do I find cheap 24 hour storage in ${city}?`,
				answer: `Most storage companies do offer discounts for first time customers, like first month free or $1 first month rent, and you can compare all storage discounts in ${city} on Sparefoot.com.`
			});

			return questions;
		}
	}
};
