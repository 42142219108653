const GoogleMapsLoader = require('@sparefoot/google-maps');

let inst;
export default class GoogleMaps {
	constructor(config) {
		const { key } = (config || {});
		this.key = key;

		if (key) {
			GoogleMapsLoader.KEY = key;
		}

		GoogleMapsLoader.LIBRARIES = ['geometry', 'places'];

		this.loadedPromise = new Promise((resolve) => {
			GoogleMapsLoader.load((google) => resolve(google));
		});
	}

	get loaded() {
		return this.loadedPromise;
	}

	// make the user wait for the googs
	get google() {
		return this.loaded;
	}

	static getInstance(config) {
		if (!inst) {
			// allow ?MAPS_KEY=foobar to simulate invalid key error
			// eslint-disable-next-line no-unused-vars
			const [text, match, ...rest] = window.location.search.match('[?&]MAPS_KEY=(([^&#]*)|&|#|$)') || [];
			if (match) {
				// eslint-disable-next-line no-param-reassign
				config.key = match;
			}
			inst = new GoogleMaps(config);
		}

		return inst;
	}

	static reset() {
		if (inst) {
			inst = null;
		}
	}
}
