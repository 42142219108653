import { createSelector } from 'reselect';

/**
 * pageType vs routePageType
 *
 * "pageType" is the initial page type used when a page is first loaded. However, when
 * pagination is used on the search page, the page is NOT reloaded. This is where the
 * dynamic "routePageType" comes into play for this important Segment analytics tracking.
 *
 * The CLP is only considered the "CLP" on the initial page load, but once users start
 * using the pagination which uses XHR requests to dynamically re-render the page, the
 * page type is now only considered the "search page".
 */

const getPageType = createSelector(
	(state) => state.pageMeta.pageType,
	(_, props) => props && props.pageType,
	(pageType, routePageType) => routePageType || pageType
);

export default getPageType;
