import simplySelfStorage from 'assets/images/logos/simply-self-storage.svg';
import cubesmart from 'assets/images/logos/cubesmart.svg';
import lifeStorage from 'assets/images/logos/lifestorage.svg';
import safeguardSelfStorage from 'assets/images/logos/safeguard-self-storage.png';
import trojanStorage from 'assets/images/logos/trojan-storage.jpg';
import priceSelfStorage from 'assets/images/logos/price-self-storage.svg';
import storageMart from 'assets/images/logos/storagemart.svg';
import allStorage from 'assets/images/logos/allstorage.svg';
import extraSpaceStorage from 'assets/images/logos/extraspace.svg';
import beyondSelfStorage from 'assets/images/logos/beyondself-storage.svg';
import publicStorage from 'assets/images/logos/publicstorage.svg';
import storageWest from 'assets/images/logos/storagewest.png';
import smartStop from 'assets/images/logos/smartstop.png';
import affordableStorage from 'assets/images/logos/affordable.png';
import secureSpace from 'assets/images/logos/secure-space.svg';
import advantageStorage from 'assets/images/logos/advantage-storage.png';
import storageSense from 'assets/images/logos/storage-sense.png';
import primeStorage from 'assets/images/logos/prime-storage.png';
import manhattanMiniStorage from 'assets/images/logos/manhattan-mini-storage.svg';
import koStorage from 'assets/images/logos/ko-storage.png';
import freeUp from 'assets/images/logos/free-up.png';
import westCoast from 'assets/images/logos/west-coast-self-storage.svg';
import storageExpress from 'assets/images/logos/storage-express.svg';
import sroa from 'assets/images/logos/storage-rentals-of-america.svg';
import goStoreIt from 'assets/images/logos/goStoreit.png'
import midgard from 'assets/images/logos/midgard.jpg'

import {
	LIFE_TO_EXR_FACILITIES,
	STORAGE_EXPRESS_ES_FACILITIES
} from 'config/extraSpace';

export const VIP_ACCOUNTS = {
	9: {
		name: 'Simply Self Storage',
		logo: simplySelfStorage
	},
	16: {
		name: 'Cubesmart',
		logo: cubesmart
	},
	351: {
		name: 'Life Storage',
		logo: lifeStorage
	},
	506: {
		name: 'West Coast Self Storage',
		logo: westCoast
	},
	532: {
		name: 'Safeguard Self Storage',
		logo: safeguardSelfStorage
	},
	601: {
		name: 'Trojan Storage',
		logo: trojanStorage
	},
	659: {
		name: 'Price Self Storage',
		logo: priceSelfStorage
	},
	690: {
		name: 'StorageMart',
		logo: storageMart,
		altLogo: manhattanMiniStorage, // ALWAYS add an altLogo if you add alternates
		alternates: [
			223272, 223273, 223274, 223275, 223276, 223277, 223278, 223279,
			223280, 223281, 223282, 223283, 223284, 223285, 223286, 223287,
			223288, 70426, 70427, 70428, 234924, 223273
		]
	},
	2278: {
		name: 'All Storage',
		logo: allStorage
	},
	5910: {
		name: 'Extra Space Storage',
		logo: extraSpaceStorage,
		altLogo: lifeStorage,
		lifeStorageLogo: lifeStorage,
		expressLogo: storageExpress,
		alternates: [
			...LIFE_TO_EXR_FACILITIES,
			...STORAGE_EXPRESS_ES_FACILITIES
		],
		storageExpressAlternates: [...STORAGE_EXPRESS_ES_FACILITIES]
	},
	7008: {
		name: 'Beyond Self Storage',
		logo: beyondSelfStorage
	},
	7180: {
		name: 'KO Storage',
		logo: koStorage
	},
	8718: {
		name: 'Public Storage',
		logo: publicStorage
	},
	3: {
		name: 'SmartStop Self Storage',
		logo: smartStop
	},
	2765: {
		name: 'Storage West',
		logo: storageWest
	},
	3919: {
		name: 'Affordable Family Storage',
		logo: affordableStorage
	},
	8733: {
		name: 'SecureSpace Self Storage',
		logo: secureSpace
	},
	1339: {
		name: 'Advantage Storage',
		logo: advantageStorage,
		exceptions: [
			211785, 209986, 216884, 212376, 208229, 216362, 217603, 206027,
			210629, 214573, 210597, 211215
		]
	},
	462: {
		name: 'Storage Sense',
		logo: storageSense,
		inclusions: [
			158174, 199455, 199529, 199590, 199839, 202168, 202570, 203082,
			203264, 203508, 203510, 203784, 203787, 204123, 205523, 206084,
			206425, 206426, 206427, 208198, 208199, 208200, 208243, 208550,
			208551, 208552, 208553, 208554, 209158, 209160, 209163, 209164,
			209165, 209354, 209355, 210312, 210313, 210314, 210316, 210317,
			210318, 210319, 210615, 210616, 210618, 210619, 210620, 210621,
			210622, 210623, 210991, 210992, 210993, 210994, 210995, 211162,
			211163, 211385, 211390, 211391, 211392, 211394, 211395, 211396,
			211397, 211399, 211608, 211609, 211610, 211648, 211891, 211892,
			211893, 212278, 212279, 212574, 212575, 212577, 212686, 212688,
			212821, 212822, 212905, 213122, 213124, 213865, 213881, 213884,
			213885, 215442, 215446, 216488, 217268, 217269, 217270, 217492,
			217494, 217573, 217587, 217690, 217788, 217925, 218358, 218360,
			218374, 218577, 218578, 218579, 218580, 218604, 218661, 218684,
			218692, 218753, 218900, 218901, 218902, 218903, 218904, 218905,
			218908, 218909, 218910, 218911, 219018, 219019, 219079, 219107,
			219132, 219173, 219174, 219310, 219311, 219475, 219516, 219859,
			219862, 220119, 220120, 220199, 220427, 220428, 220654, 220670,
			220755, 220756, 220757, 220758, 220764, 221127, 221153, 221154,
			221239, 221653, 221807, 221920, 221922, 221923, 221989, 221992,
			221993, 221994, 221995, 221996, 221998, 221999, 222000, 222001,
			222002, 222003, 222056, 222057, 222058, 222059, 222061, 222151,
			222153, 222154, 222155, 222156, 222358, 222360, 222388, 222769,
			222871, 222994, 223242, 223243, 223422, 223495, 223809, 223843,
			223855, 224315, 224357, 224358, 224359, 224360, 224361, 224452,
			224524, 224872, 224873, 224874, 225046, 225109, 225110, 225152,
			225153, 225359, 225433, 225434, 225435, 225467, 226246, 226247,
			226248, 226249, 226301, 226350, 226477, 226603, 226605, 226620,
			226719, 226751, 226783, 226803, 226938, 226941, 226943, 226944,
			226945, 226946, 227016, 227330, 227746, 227795, 227828, 228188,
			228411, 228412, 228672, 228673, 228844, 228845, 228846, 228847,
			228848, 229341, 229614, 229615, 229616, 229728, 231814, 231833,
			231834, 231835, 233051, 233052, 233420, 233421, 233492, 233682,
			233741, 233743, 233876, 234027, 234292, 234495, 234537, 236082
		]
	},
	3918: {
		name: 'Prime Storage',
		logo: primeStorage
	},
	8153: {
		name: 'FreeUp Storage',
		logo: freeUp,
		exceptions: [220616]
	},
	6443: {
		name: 'Elite Stor Capital Partners / SROA',
		logo: sroa
	},
	7419: {
		name: 'Go Store It',
		logo: goStoreIt
	},
	3969: {
		name: 'Reliant',
		logo: midgard
	}
};
