import React from 'react';
import PropTypes from 'prop-types';
import withHeadlineProps from './headlineProps';
import './headline.scss';

/**
 * H4 text component
 */

export function H4(props) {
	return (
		<h4 className={props.className}>
			{props.children}
		</h4>
	);
}

H4.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node
};

export default withHeadlineProps(H4);
