import React from 'react';
import PropTypes from 'prop-types';

import { CityLandingPages } from 'components/landing/CityLandingPages';

import './FeaturedCitiesSection.scss';

export default function FeaturedCitiesSection({ cities }) {
	return (
		<CityLandingPages
			headline="Featured Cities"
			subheading="Find storage units in these popular locations:"
			cities={cities}
			segmentCategory="storage cities"
			fillOrder="vertical"
			className="featured-cities"
		/>
	);
}

FeaturedCitiesSection.propTypes = {
	cities: PropTypes.arrayOf(
		PropTypes.shape({
			url: PropTypes.string,
			name: PropTypes.string,
			city: PropTypes.string
		})
	)
};
