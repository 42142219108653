import React from 'react';
import { filter, includes, concat } from 'lodash';

import outdoorUnit from 'assets/images/unit-icons/outdoor-unit.svg';
import outdoorCoveredUnit from 'assets/images/unit-icons/outdoor-covered-unit.svg';
import indoorUnit from 'assets/images/unit-icons/indoor-unit.svg';
import enclosedUnit from 'assets/images/unit-icons/enclosed-unit.svg';

import { PenskeRental, TruckRental, FreeTruckRental, TruckRentalText, QuestionMark } from 'components/svg';

import { Text } from 'components/core/Typography';
import { Image } from 'components/core/Image';
import { Tooltip } from 'components/core/Tooltip';
import { UnitIcon } from 'components/unit/UnitIcon';

export const unitGroupList = [
	{ display: '5\' x 5\'', value: '5x5' },
	{ display: '5\' x 10\'', value: '5x10' },
	{ display: '5\' x 15\'', value: '5x15' },
	{ display: '10\' x 10\'', value: '10x10' },
	{ display: '10\' x 15\'', value: '10x15' },
	{ display: '10\' x 20\'', value: '10x20' },
	{ display: '10\' x 30\'', value: '10x30' }
];

export const unitPriceBuckets = [
	{
		key: '5x5',
		label: '5\' x 5\'',
		sqft: '20-37'
	},
	{
		key: '5x10',
		label: '5\' x 10\'',
		sqft: '37-62'
	},
	{
		key: '10x10',
		label: '10\' x 10\'',
		sqft: '87-125'
	}
];

export const truckTypes = [
	{
		value: 'allTruckRental',
		icon: (<TruckRental />),
		label: 'All Types of Trucks + Storage'
	},
	{
		value: 'isPenske',
		label: 'Penske Trucks + Storage',
		icon: (<PenskeRental />)
	},
	{
		value: 'freeTruckRental',
		icon: (<FreeTruckRental />),
		label: 'Free Trucks + Storage'
	},
	{
		value: 'truckRental',
		icon: (<TruckRentalText />),
		label: 'Truck rental + Storage'
	}
];

export const unitSizesBySearchType = {
	'storage-only': [
		{
			label: '5′ x 5′',
			value: '20-37',
			icon: (<UnitIcon unitSize="5x5-storage" alt="5 x 5 storage unit icon"/>),
			segmentLabel: 'unit-5x5'
		},
		{
			label: '5′ x 10′',
			value: '37-62',
			icon: (<UnitIcon unitSize="5x10-storage" alt="5 x 10 storage unit icon"/>),
			segmentLabel: 'unit-5x10'
		},
		{
			label: '5′ x 15′',
			value: '62-87',
			icon: (<UnitIcon unitSize="5x15-storage" alt="5 x 15 storage unit icon"/>),
			segmentLabel: 'unit-5x15'
		},
		{
			label: '10′ x 10′',
			value: '87-125',
			icon: (<UnitIcon unitSize="10x10-storage" alt="10 x 10 storage unit icon"/>),
			segmentLabel: 'unit-10x10'
		},
		{
			label: '10′ x 15′',
			value: '125-175',
			icon: (<UnitIcon unitSize="10x15-storage" alt="10 x 15 storage unit icon"/>),
			segmentLabel: 'unit-10x15'
		},
		{
			label: '10′ x 20′',
			value: '175-250',
			icon: (<UnitIcon unitSize="10x20-storage" alt="10 x 20 storage unit icon"/>),
			segmentLabel: 'unit-10x20'
		},
		{
			label: '10′ x 30′',
			value: '250-999',
			icon: (<UnitIcon unitSize="10x30-storage" alt="10 x 30 storage unit icon"/>),
			segmentLabel: 'unit-10x30'
		}
	],
	parking: [
		{
			label: '15′ Long',
			value: '15',
			maxLength: '19.99',
			icon: (<UnitIcon unitSize="10x15-parking_space" alt="10 x 15 park space icon"/>),
			segmentLabel: 'parking-10x15'
		},
		{
			label: '20′ Long',
			value: '20',
			maxLength: '24.99',
			icon: (<UnitIcon unitSize="10x20-parking_space" alt="10 x 20 park space icon"/>),
			segmentLabel: 'parking-10x20'
		},
		{
			label: '25′ Long',
			value: '25',
			maxLength: '29.99',
			icon: (<UnitIcon unitSize="10x25-parking_space" alt="10 x 25 park space icon"/>),
			segmentLabel: 'parking-10x25'
		},
		{
			label: '30′ Long',
			value: '30',
			maxLength: '34.99',
			icon: (<UnitIcon unitSize="10x30-parking_space" alt="10 x 30 park space icon"/>),
			segmentLabel: 'parking-10x30'
		},
		{
			label: '35′ Long',
			value: '35',
			maxLength: '39.99',
			icon: (<UnitIcon unitSize="10x35-parking_space" alt="10 x 35 park space icon"/>),
			segmentLabel: 'parking-10x35'
		},
		{
			label: '40′ Long',
			value: '40',
			maxLength: '49.99',
			icon: (<UnitIcon unitSize="10x40-parking_space" alt="10 x 40 park space icon"/>),
			segmentLabel: 'parking-10x40'
		},
		{
			label: '50′ Long',
			value: '50',
			maxLength: '999',
			icon: (<UnitIcon unitSize="10x50-parking_space" alt="10 x 50 park space icon"/>),
			segmentLabel: 'parking-10x50'
		}
	]
};


export const storageBySizeBuckets = {
	'storage-only': [
		{
			label: 'Small Units',
			value: '25-75',
			secondaryText: 'Fits a closet or small bedroom',
			icon: <UnitIcon alt='Small Units' unitSize="5x5-storage" lazyLoad={true}/>,
			tooltipContent: (
				<div>
					<span>
						Ideal for season decor, sporting equipment, business
						inventory, or small household items.
					</span>
					<ul>
						<li><span>25-74 Sqare Feet</span></li>
						<li><span>Example Sizes: 5' x 5' - 5' x 15'</span></li>
					</ul>
				</div>
			),
			segmentLabel: 'unit-small'
			
			
		},
		{
			label: 'Medium Units',
			value: '76-200',
			secondaryText: 'Fits a 1-2 bedroom apartment',
			icon: <UnitIcon alt="mediumUnits" unitSize="10x10-storage" lazyLoad={true}/>,
			tooltipContent: (
				<div>
					<span>
					Ideal for a one-bedroom apartment all the way up to a two-to-three bedroom house, depending on the amount of furniture and large appliances.
					</span>
					<ul>
						<li><span>75-199 Sqare Feet</span></li>
						<li><span>Example sizes: 10’ x 10’ - 10’ x 20'</span></li>
					</ul>
				</div>
			),
			segmentLabel: 'unit-medium',
		},
		{
			label: 'Large Units',
			value: '201-999',
			secondaryText: 'Fits a 3-5 bedroom house',
			icon: <UnitIcon alt="LargeUnits" unitSize="10x20-storage" lazyLoad={true}/>,
			tooltipContent: (
				<div>
					<span>
					Ideal for a three-bedroom house or a four-to-five bedroom house with large appliances, like a washer and dryer and refrigerator.
					</span>
					<ul>
						<li><span>200+ Sqare Feet</span></li>
						<li><span>Example sizes: 10’ x 25’ - 10’ x 30’</span></li>
					</ul>
				</div>
			),
			segmentLabel: 'unit-large',
		}
	],
};



export const vehicleUnitAccommodations = [
	{
		label: 'Outdoor Uncovered Unit',
		value: 'outdoor-uncovered',
		icon: (<Image src={outdoorUnit} className="accommodation-icon" alt="accommodation-icon" />),
		segmentLabel: 'vehicleUnitAccommodation:outdoor',
		descriptionText: 'An Outdoor Uncovered unit is a parking space similar to a traditional parking lot.'
	},
	{
		label: 'Outdoor Covered Unit',
		value: 'outdoor-covered',
		icon: (<Image src={outdoorCoveredUnit} className="accommodation-icon" alt="accommodation-icon"/>),
		segmentLabel: 'vehicleUnitAccommodation:outdoor-covered',
		descriptionText: `You'll typically park your vehicle under a standalone roof alongside
			other vehicles like a carport.`
	},
	{
		label: 'Indoor Unit',
		value: 'indoor',
		icon: (<Image src={indoorUnit} className="accommodation-icon" alt="accommodation-icon"/>),
		segmentLabel: 'vehicleUnitAccommodation:indoor',
		descriptionText: `You won't get as much protection as an enclosed unit, but the roof
		structure will protect from the harshest elements.`
	},
	{
		label: 'Enclosed Unit',
		value: 'enclosed',
		icon: (<Image src={enclosedUnit} className="accommodation-icon" alt="accommodation-icon"/>),
		segmentLabel: 'vehicleUnitAccommodation:enclosed',
		descriptionText: `Car storage units that essentially act as a garage that you drive
			straight into, and are accessed via a large roll-up door.`
	}
];

export const amenities = [
	{
		checkboxQueryParam: 'hasContactlessMoveins',
		label: (
			<span>
				Contactless move-in {' '}
				<Tooltip
					size="medium"
					content={
						<Text size="tiny">
							Show facilities that provide contactless move-ins during the current COVID-19 pandemic.
						</Text>
					}
					segmentLabel="contactless search filter tooltip"
					tooltipShowing
					onFocus
					plainText
					clickOnMobile
				>
					<QuestionMark />
				</Tooltip>
			</span>
		)
	},
	{
		checkboxQueryParam: 'climateControlled',
		label: 'Climate controlled'
	},
	{
		checkboxQueryParam: 'firstFloor',
		label: 'First floor'
	},
	{
		checkboxQueryParam: 'twentyFourHourAccess',
		label: '24-hour access'
	},
	{
		checkboxQueryParam: 'eGateAccess',
		label: 'Electronic gate access'
	},
	{
		checkboxQueryParam: 'driveUp',
		label: 'Drive-up access'
	},
	{
		checkboxQueryParam: 'kiosk',
		label: 'Self-service kiosk'
	},
	{
		checkboxQueryParam: 'alarm',
		label: 'Alarm in unit'
	},
	{
		checkboxQueryParam: 'fencedAndLit',
		label: 'Fenced and lit'
	},
	{
		checkboxQueryParam: 'cameras',
		label: 'Surveillance cameras'
	},
	{
		checkboxQueryParam: 'getOpenNow',
		label: 'Is open'
	},
	{
		checkboxQueryParam: 'hasSpecial',
		label: 'Has special'
	}
];

// sf_13380_sfdc_new_filter 
export const unitAmenities = [
	{
		checkboxQueryParam: 'climateControlled',
		label: 'Climate controlled'
	},
	{
		checkboxQueryParam: 'driveUp',
		label: 'Drive-up access'
	},
	{
		checkboxQueryParam: 'hasSpecial',
		label: 'Has special'
	},
	{
		checkboxQueryParam: 'firstFloor',
		label: 'First floor'
	},
	
	{
		checkboxQueryParam: 'alarm',
		label: 'Alarm secured unit'
	}
];

// sf_13380_sfdc_new_filter 
export const facilityAmenities = [
	{
		checkboxQueryParam: 'twentyFourHourAccess',
		label: '24-hour access'
	},
	{
		checkboxQueryParam: 'hasContactlessMoveins',
		label: 'Contactless move-in'
	},
	{
		checkboxQueryParam: 'cameras',
		label: 'Surveillance cameras'
	},
	{
		checkboxQueryParam: 'eGateAccess',
		label: 'Electronic gate access'
	},
	{
		checkboxQueryParam: 'getOpenNow',
		label: 'Facility is currently open'
	},
	{
		checkboxQueryParam: 'fencedAndLit',
		label: 'Fenced and lit'
	},
	{
		checkboxQueryParam: 'kiosk',
		label: 'Self-service kiosk'
	}
];

export const vehicleUnitAmenities = concat(
	filter(
		amenities, (amenity) => !includes(['climateControlled', 'firstFloor'], amenity.checkboxQueryParam)
	),
	[
		{
			checkboxQueryParam: 'pullthrough',
			label: 'Pull-through access'
		}
	]
);

export const vehicleStorageRequirements = [
	{
		checkboxQueryParam: 'vehicleTitleNotRequired',
		label: 'Title not required'
	},
	{
		checkboxQueryParam: 'vehicleRegistrationNotRequired',
		label: 'Registration not required'
	},
	{
		checkboxQueryParam: 'vehicleInsuranceNotRequired',
		label: 'Insurance not required'
	},
	{
		checkboxQueryParam: 'vehicleDrivableNotRequired',
		label: 'Doesn\'t have to be drivable'
	}
];

export const unitSqftToSizeBucket = {
	'20-37': '5x5',
	'37-62': '5x10',
	'62-87': '5x15',
	'87-125': '10x10',
	'125-175': '10x15',
	'175-250': '10x20',
	'250-999': '10x30'
};

export const vehicleSqftToSizeBucket = {
	'125-175': '10x15',
	'175-225': '10x20',
	'225-275': '10x25',
	'275-325': '10x30',
	'325-375': '10x35',
	'375-425': '10x40',
	'425-999': '10x50'
};

// sf_12496_sfdc_clp_unitcardrefresh
export const sizeGuide = {
	'5x5': {
		looksLike: 'A small closet',
		goodFor: "The contents of one large closet. This size is ideal for seasonal decorations, personal items, gardening equipment, camping gear, children's toys, or off-season clothing."
	},
	'5x10': {
		looksLike: 'A walk-in closet',
		goodFor: 'The contents of one bedroom or dorm room, such as a mattress set, couch, table, chairs, and boxes.'
	},
	'5x15': {
		looksLike: 'A large walk-in closet',
		goodFor: 'The contents of two rooms, such as large dining sets, desks, cabinets, mattress sets, and washer/dryer. '
	},
	'10x10': {
		looksLike: 'A small bedroom',
		goodFor: 'The contents of one large bedroom, such as bedroom/living sets, refrigerator, large drawers, shelves, and multiple boxes.'
	},
	'10x15': {
		looksLike: 'A small one-car garage',
		goodFor: 'The contents of three to four rooms, such as multiple bedroom sets, large appliances and patio sets. May also fit smaller cars, boats, and other small vehicles.'
	},
	'10x20': {
		looksLike: 'A standard one-car garage',
		goodFor: 'The contents of a two to three-bedroom house, such as multiple bedroom sets, and large furniture. Can also fit trucks, trailers, and other mid-size vehicles.'
	},
	'10x30': {
		looksLike: 'A large car garage. Often the largest unit size at most facilities.',
		goodFor: 'The contents of a four- to six-bedroom house, such as mattress sets, large furniture, major appliances, seasonal items, outdoor equipment, and bikes. Will fit most vehicles.'
	}
};
