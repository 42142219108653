import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import withHeadlineProps from './headlineProps';
import './headline.scss';

/**
 * Hero Header text component
 */
export function HH({ className, children }) {
	return (
		<h1 className={classNames('hero-header', className)}>
			{children}
		</h1>
	);
}

HH.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string
};


export default withHeadlineProps(HH);
