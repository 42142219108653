import { createSelector } from 'reselect';
import {
	get, assign, sortBy, capitalize, isFunction, includes, filter, isEmpty
} from 'lodash';
import parse from 'date-fns/parse';
import isValid from 'date-fns/is_valid';
import { PageTypes } from '@sparefoot/segment-react';
import { VIP_ACCOUNTS } from 'config/accounts';
import { BADGES } from 'config/badges';
import faqByPage from 'config/faqByPage';

import { mergeUrlQueryParams } from 'utils/url';
import { getSizeBucketFromSearchTypeSqft } from 'utils/units';
import { serviceAreaColors } from 'utils/colors';
import { getScarcityFlag } from 'utils/store/getScarcityFlag';

import getPageType from './getPageType';

export function setUnitSizeFilterUrls(facility, searchType, sqft) {
	if (!facility) {
		return facility;
	}

	const params = { searchType };

	if (sqft) {
		params.unitSizeFilters = getSizeBucketFromSearchTypeSqft(searchType, sqft);
	}

	return {
		...facility,
		url: {
			...facility.url,
			// Add contextual URL params for vehicle and sqft searches
			facility: (searchType !== 'storage' || params.unitSizeFilters) ?
				mergeUrlQueryParams(facility.url.facility, params) :
				facility.url.facility
		},
		units: facility.units.map((unit) => {
			const unitParams = {
				searchType,
				unitSizeFilters: unit.sizeBucket,
				unitType: unit.unitTypeText,
				unitId: unit.id
			};

			return {
				...unit,
				facilityUrl: mergeUrlQueryParams(unit.facilityUrl, unitParams)
			};
		})
	};
}

export const getSearchFacilities = createSelector(
	(state) => state.search.facilities,
	(state) => state.search.searchType,
	(state) => state.search.searchParams,
	(state) => state.facilities.byId,
	(state) => state.units.byId,
	(state) => state.reviews.byId,
	(state) => state.search.page,
	(state) => state.search.facilitiesPerPage,
	(state) => state.app.cloudfrontUrl,
	(state) => state.experiments.bucket,
	(
		facilityIds,
		searchType,
		searchParams,
		facilities,
		units,
		reviews,
		page,
		facilitiesPerPage,
		cloudfrontUrl,
		experimentsBucket
	) => {
		const badgeNames = Object.keys(BADGES);
		const searchOrder = get(searchParams, 'order', 'recommended');
		let topYielderFacilities = page === 1 && searchOrder === 'recommended' && filter(
			facilities, (facility) => facility.cardType === 'topYielderCard'
		);
		const facilityIdsMap = facilityIds.map((facilityId, index) => {
			let facility = facilities[facilityId];
			// Show the recommended unit for size filters or if no units are returned
			const showRecommendedUnit = facility.recommendedUnit && (searchParams.sqft || !facility.units.length);
			facility = {
				...facility,
				index: index + 1,
				position: (index + 1) + ((page - 1) * facilitiesPerPage),
				color: facility.serviceArea ? serviceAreaColors[index % serviceAreaColors.length] : null,
				units: showRecommendedUnit ?
					[units[facility.recommendedUnit]] :
					facility.units.map((unit) => units[unit]),
				bestReview: facility.bestReviewId ? reviews[facility.bestReviewId] : null,
				ecommerceBadges: {},
				cardType: 'facilityCard',
				cloudfrontUrl,
				hasOnlineMoveIns: get(facility, 'hasOnlineMoveins', false) // sf_11349_sfdc_omi
			};

			// sf_11349_sfdc_omi
			if (facility.hasOnlineMoveIns && get(experimentsBucket, 'sf_11349_sfdc_omi', '') === 'variation') {
				facility.badges = facility.badges.filter((badge) => badge !== 'omi');
			}

			facility.badges = sortBy(
				facility.badges, (a) => (BADGES[a].rank)
			);

			facility.ecommerceBadges = badgeNames.reduce((acc, badge) => ({
				...acc,
				[`has${capitalize(badge)}Badge`]: facility.badges.indexOf(badge) !== -1
			}), {});

			facility.hasScarcity = getScarcityFlag(facility.units);
			return setUnitSizeFilterUrls(facility, searchType, searchParams.sqft);
		});

		if (!isEmpty(topYielderFacilities)) {
			topYielderFacilities = topYielderFacilities.map((facility) => ({
				...facility,
				units: facility.units.map((unit) => units[unit])
			}));
			facilityIdsMap.splice(3, 0, ...topYielderFacilities);
		}

		return facilityIdsMap;
	}
);

export const getMoveInDate = createSelector(
	(state) => state.search.searchParams.moveInDate,
	(moveInDate) => {
		if (!moveInDate) {
			return null;
		}

		const mid = parse(moveInDate);
		return isValid(mid) ? mid : null;
	}
);

export const getSearchTerm = createSelector(
	(state) => state.search.searchParams.location,
	(state) => state.search.location.text,
	(location, text) => {
		// Searches based on map movements return a {lat, long} string in searchParams.location,
		// We use the geocoded location.text field for these values instead
		const isLatLngStr = location && location.match(/([-\d.]+(,\s)?)/);
		return isLatLngStr ? text : location;
	}
);

export const getLocationLabel = createSelector(
	(state) => state.search.location.city,
	(state) => state.search.location.state,
	(city, state) => `${city}${state ? `, ${state}` : ''}`
);

export const getSearchType = createSelector(
	(state) => state.queryParams,
	(state) => state.search,
	// Check queryParams first so that UI can hide/show etc before changes in search return
	(queryParams, search) => ((queryParams && queryParams.searchType) ? queryParams.searchType : search.searchType),
);

export const getLandingFacilities = createSelector(
	(state) => state.search.landingFacilities,
	(state) => state.facilities.byId,
	(landingFacilityIds, facilities) => landingFacilityIds.map((facilityId, index) => {
		let facility = facilities[facilityId];

		facility = {
			...facility,
			index: index + 1,
			position: index + 1
		};

		return facility;
	})
);

export const googleAutocompleteSelector = createSelector(
	(state) => state.app.googleMapsKey,
	(googleMapsKey) => ({ googleMapsKey })
);

export const searchMapSelector = createSelector(
	getSearchFacilities,
	(state) => state.search,
	googleAutocompleteSelector,
	(facilities, search, googleMapsKey) => ({
		...googleMapsKey,
		facilities,
		localPhone: search.localPhone,
		page: search.page,
		facilitiesPerPage: search.facilitiesPerPage,
		totalFacilities: search.totalFacilities,
		searchLocation: search.location,
		staticMapUrl: search.staticMapUrl
	})
);

const isVipCardAccount = (firstFacilityAccountId, firstFacility) => {
	const imagesLength = get(firstFacility, 'images.length', 0) >= 1;
	const getAccountFromId = VIP_ACCOUNTS[firstFacilityAccountId];

	if (get(getAccountFromId, 'inclusions.length')) {
		return includes(get(getAccountFromId, 'inclusions', []), firstFacility.id) &&
			imagesLength && getAccountFromId;
	}
	return !includes(get(getAccountFromId, 'exceptions', []), firstFacility.id) &&
		imagesLength && getAccountFromId;
};

export const vipCardSelector = createSelector(
	getSearchFacilities,
	(state) => state.search,
	(state) => state.app.cloudfrontUrl,
	(facilities, search, cloudfrontUrl) => {
		const { page, searchType } = search;
		const isFirstPage = page === 1;
		const firstFacility = isFirstPage && facilities.length && facilities[0];
		const firstFacilityAccountId = get(firstFacility, 'account.id');
		const vipAccount = isVipCardAccount(firstFacilityAccountId, firstFacility);
		const isAlternate = includes(get(vipAccount, 'alternates', []), firstFacility.id);

		if (vipAccount) {
			vipAccount.isAlternate = isAlternate;
		}

		if (isAlternate && vipAccount.name === 'Extra Space Storage') {
			const isStorageExpressAlternate = includes(get(vipAccount, 'storageExpressAlternates', []), firstFacility.id);
			vipAccount.altLogo = isStorageExpressAlternate ? vipAccount.expressLogo : vipAccount.lifeStorageLogo;
		}

		return {
			vipAccount,
			firstListingIsVipEligible: vipAccount && isFirstPage && searchType === 'storage',
			vipFacilityId: vipAccount ? firstFacility.id : null,
			vipFacilityImages: vipAccount ? get(firstFacility, 'images', []).map((image) => ({
				src: `${cloudfrontUrl}/large-compress/${image}`,
				alt: firstFacility.name
			})) : []
		};
	}
);

export const storageDealsSelector = createSelector(
	getPageType,
	(state) => get(state, 'search.promoCounts', { promoFacilitiesCount: 0, fmfFacilitiesCount: 0 }),
	(pageType, { promoFacilitiesCount, fmfFacilitiesCount }) => ({
		isStorageDealsPage: pageType === PageTypes.STORAGE_DEALS_LANDING,
		fmfFacilitiesCount,
		promoFacilitiesCount
	})
);

export const faqContentSelector = createSelector(
	(state) => state.search,
	({ location, content, searchParams }) => {
		let output = [];
		const seoBodyJson = get(content, 'seoBodyJson', []) || [];

		seoBodyJson.forEach((section) => {
			if (section.type === 'faq_section') {
				output = output.concat(section.value.map((value) => ({
					question: `### ${value.q}`,
					answer: value.a
				})));
			}
		});

		if (output.length) {
			return {
				faqContent: output,
				faqShowing: true
			};
		}

		// Vehicle storage can be car, RV or boat storage
		let functionPath = searchParams.subPageType;
		if (searchParams.subPageType === 'vehicleLandingPage') {
			functionPath = `${searchParams.subPageType}.${searchParams.vehicle}`;
		}

		// Amenity storage can be climate-controlled or 24-hour-access
		if (searchParams.subPageType === 'amenityLandingPage') {
			functionPath = `${searchParams.subPageType}.${searchParams.amenity}`;
		}

		const faqFunction = get(faqByPage, functionPath, []);

		if (isFunction(faqFunction)) {
			const { city, state } = location;
			const avgPrices = get(content, 'pricingStats.averagePrices', null);
			const faqContent = avgPrices ? faqFunction(city, state, avgPrices) : [];
			return {
				faqContent,
				faqShowing: !!faqContent.length
			};
		}
		return {};
	}
);

export const headlineSelector = createSelector(
	(state) => state.search,
	({ meta, content, location }) => {
		let headline = get(meta, 'headline', '');
		let subtitle = get(content, 'subtitle', '');
		headline = headline && headline.length ? headline : `Cheap Storage Units in ${location.city}, ${location.state}`;
		subtitle = subtitle && subtitle.length ? subtitle : 'Find Self-Storage Near You';
		return ({
			metaHeadline: headline,
			primaryHeadline: headline,
			secondaryHeadline: subtitle
		});
	}
);

export const searchSelector = createSelector(
	getSearchFacilities,
	getMoveInDate,
	(state) => state.pageMeta.phone,
	getSearchTerm,
	getSearchType,
	getLocationLabel,
	(state) => state.search,
	getPageType,
	storageDealsSelector,
	vipCardSelector,
	(state) => state.search.badgedFacilities,
	faqContentSelector,
	(state) => state.app.baseUrl,
	headlineSelector,
	(
		facilities, moveInDate, phone, searchTerm, searchType, locationLabel,
		search, pageType, storageDealsFlags, vipFacility, badgedFacilities, faqContent,
		baseUrl, { metaHeadline, primaryHeadline, secondaryHeadline }
	) => (
		{
			moveInDate,
			phone,
			canonicalUrl: search.canonicalUrl,
			cityUrl: get(search, 'url.city', ''),
			facetCounts: search.facetCounts,
			facilities,
			facilitiesPerPage: search.facilitiesPerPage,
			isBellhopsMarket: get(search, 'isBellhopsMarket', false),
			isTruckSerp: searchType === 'trucks',
			isVehicleSerp: searchType === 'vehicle',
			loading: search.loading,
			localPhone: search.localPhone,
			locationLabel,
			metaHeadline,
			numPages: search.numPages,
			page: search.page,
			primaryHeadline,
			searchContent: search.content,
			searchContentId: get(search.content, 'contentId'),
			searchId: search.searchId,
			searchLocation: search.location,
			searchParams: search.searchParams,
			searchTerm,
			searchType,
			searchPath: search.canonicalUrl,
			secondaryHeadline,
			stateUrl: get(search, 'url.state', ''),
			totalBookings: Math.ceil(get(search, 'content.cityTotalBookings', 0) / 10) * 10,
			totalFacilities: search.totalFacilities,
			lastInOriginalSearch: search.lastInOriginalSearch,
			mapRedoSearch: search.mapRedoSearch,
			pageType,
			...storageDealsFlags,
			...vipFacility,
			badgedFacilities,
			...faqContent,
			metaTitle: get(search, 'meta.title', ''),
			metaDescription: get(search, 'meta.description', ''),
			baseUrl
		}
	)
);

export const landingFacilitiesSelector = createSelector(
	getPageType,
	getLandingFacilities,
	(state) => state.pageMeta.phone,
	(state) => state.search.url,
	(state) => state.search.landingLocation,
	(state) => state.search.searchId,
	(state) => state.facilities.storageCities,
	(state) => state.pageMeta.tags,
	headlineSelector,
	(
		pageType, facilities, phone, url, landingLocation,
		searchId, storageCities, tags, { primaryHeadline, secondaryHeadline }
	) => assign({
		pageType,
		phone,
		landingCityUrl: url.city,
		searchId,
		location: landingLocation,
		facilities,
		primaryHeadline,
		secondaryHeadline,
		metaTitle: get(tags, 'title', ''),
		metaDescription: get(tags, 'meta.description', ''),
		canonicalUrl: get(tags, 'links[0].content', '')
	}, pageType === 'storageLandingPage' && { storageCities })
);
