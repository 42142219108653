import React from 'react';
import PropTypes from 'prop-types';

import { Image } from 'components/core/Image';
import { H2, H3, Paragraph } from 'components/core/Typography';
import { IconText } from 'components/core/IconText';

import stuffIllustration from 'assets/images/homepage/stuff-move-in.svg';
import searchIcon from 'assets/images/icons//how-it-works/lens.svg';
import filtersIcon from 'assets/images/icons//how-it-works/filters.svg';
import priceTagIcon from 'assets/images/icons//how-it-works/price-tag.svg';
import noCreditCardIcon from 'assets/images/icons//how-it-works/no-credit-card.svg';
import facilityIcon from 'assets/images/icons//how-it-works/facility.svg';
import cursorIcon from 'assets/images/icons//how-it-works/cursor.svg';

import './HowItWorksSection.scss';

const selfStorageOptions = [
	{
		icon: searchIcon,
		text: 'Search by address, city, or ZIP code for storage in your area',
		alt: "searchIcon"
	},
	{
		icon: filtersIcon,
		text: 'Narrow your search by location, price, size or amenities',
		alt: "filterIcon"
	},
	{
		icon: priceTagIcon,
		text: 'Find the best deal that meets your storage needs',
		alt: "priceTagIcon"
	},
	{
		icon: noCreditCardIcon,
		text: 'Make a risk-free reservation online, no credit card required',
		alt: "noCreditCardIcon"
	},
	{
		icon: cursorIcon,
		text: 'Complete your storage rental online, if available',
		alt: "cursorIcon"
	},
	{
		icon: facilityIcon,
		text: 'Visit the storage facility and move into your unit',
		alt: "facilityIcon"
	}
];

function HowItWorksSection({ isHomePage = true, options = selfStorageOptions }) {
	return (
		<div className="section-content">
			<div className="how-it-works">
				{isHomePage && (
					<div>
						<Image src={stuffIllustration} alt="illustration" lazyLoad={true} className="truck-illustration" height="91" width="300" />
						<H2 size="small" weight="standard" color="gray" family="rounded">
							Why Us?
						</H2>
						<H3 size="semiLarge" family="rounded" className="sparefoot-inventory-title">
							SpareFoot Offers the Largest Inventory of Self-Storage Units Online
						</H3>
						<Paragraph size="small" weight="standard" color="gray">
							SpareFoot has the best selection of storage units online, with over 20,000
							facilities across the U.S. to choose from.
						</Paragraph>
						<Paragraph size="small" weight="standard" color="gray">
							Need specialty storage? Search for wine storage, facilities with military
							discounts, vehicle storage, storage for students, and more.
						</Paragraph>
					</div>
				)}
				<div>
					<H2 size="small" weight="standard" color="gray" family="rounded">
						How it Works
					</H2>
					<H3 size="semiLarge" family="rounded">
						Compare all self-storage options in one place.
					</H3>
					<ul>
						{options.map((item) => (
							<li key={item.text}>
								<IconText
									key={`option-${item.text}`}
									src={item.icon}
									size="small"
									weight="standard"
									color="gray"
									last
									alt={item.alt}
									height={52}
									width={52}
								>
									{item.text}
								</IconText>
							</li>
						))}
					</ul>
				</div>
			</div>

		</div>
	);
}

HowItWorksSection.propTypes = {
	isHomePage: PropTypes.bool,
	options: PropTypes.arrayOf(PropTypes.shape({
		icon: PropTypes.string,
		text: PropTypes.string
	}))
};

export default HowItWorksSection;
