import React from 'react';
import PropTypes from 'prop-types';
import withStateHandlers from 'recompose/withStateHandlers';
import compose from 'recompose/compose';
import { omit } from 'lodash';

import withSearchHandler from 'decorators/withSearchHandler';

import { SelectOptionsGroup } from 'components/core/SelectOptionsGroup';
import { HH, H2 } from 'components/core/Headline';
import { withSegmentContext } from 'components/core/library/segment';
import { SearchBox } from 'components/search/SearchBox';
import { StickySearchBar } from 'components/landing/StickySearchBar';

const options = [
	{
		display: 'Self-storage',
		value: ''
	},
	{
		display: 'Car storage',
		value: 'car'
	},
	{
		display: 'RV storage',
		value: 'rv'
	},
	{
		display: 'Boat storage',
		value: 'boat'
	}
];

export const enhance = compose(
	withSegmentContext,
	withStateHandlers({ searchType: '', additionalSearchParams: {} }, {
		handleSelectProduct: (props) => (searchType) => {
			const newState = { searchType, additionalSearchParams: props.additionalSearchParams };
			if (['car', 'rv', 'boat'].includes(searchType)) {
				newState.searchType = 'vehicle';
				newState.additionalSearchParams.vehicle = searchType;
			} else {
				newState.additionalSearchParams = omit(props.additionalSearchParams, ['vehicle']);
			}
			return newState;
		}
	}),
	withSearchHandler
);

export function HomePageHero({ handleSelectProduct, handleSearch }) {
	return (
		<div className="hero">
			<div className="section-content">
				<HH rounded size="extra-large">
					Find your stuff a home.
				</HH>
				<H2 size="medium">
					<strong>
						Find and reserve storage in minutes with SpareFoot.
					</strong>
				</H2>
				<div className="search-container" id="home-page-sticky-search">
					<div className="select-container">
						<div className="select-label">
							<strong>Show me:</strong>
						</div>
						<SelectOptionsGroup
							id="search-type"
							options={options}
							onChange={handleSelectProduct}
							selected=""
							name="search-type"
							groupType="single"
							segmentPrefix="product select"
						/>
					</div>
					<SearchBox
						segmentPrefix="search box"
						handleSearch={handleSearch}
						darkButton
					/>
				</div>
			</div>
			<StickySearchBar triggerId="home-page-sticky-search" handleSearch={handleSearch} />
		</div>
	);
}

HomePageHero.propTypes = {
	handleSearch: PropTypes.func,
	handleSelectProduct: PropTypes.func
};

export default enhance(HomePageHero);
