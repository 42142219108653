/* eslint-disable max-len */

import { MedicalCross, PointerArrow } from 'components/svg';

export const BADGES = {
	omi: {
		rank: 1,
		name: 'E-RENTAL',
		color: 'orange',
		icon: PointerArrow,
		tooltipMessage: 'Rent online, skip the counter and go straight to your space.',
		facilityMessage: 'Rent online, skip the counter and go straight to your space.'
	},
	omiBadge: {
		rank: 1,
		name: 'RENT ONLINE',
		color: 'orange',
		icon: PointerArrow,
		tooltipMessage: 'Complete your rental process online today and go straight to your unit when you plan to move in.',
		facilityMessage: 'Complete your rental process online today and go straight to your unit when you plan to move in.'
	},
	contactless: {
		rank: 4,
		name: 'CONTACTLESS',
		color: 'blue',
		icon: MedicalCross,
		tooltipMessage: 'Facilities with this badge offer contactless move-ins.',
		facilityMessage: 'This facility provides contactless move-ins.'
	}
};

export const NEW_BADGES = {
	omi: {
		name: 'ONLINE MOVE-INS',
		rank: 1,
		className: 'omi',
		tooltipHeader: 'Online Move-Ins Available:',
		tooltipMessage: 'Skip the wait at the storage facility! You’ll have the option to complete your move-in and rental paperwork online after making a free reservation.'
	},
	contactless: {
		name: 'CONTACTLESS',
		rank: 2,
		className: 'contactless',
		tooltipHeader: 'Contactless:',
		tooltipMessage: 'This facility provides contactless move-ins.'
	}
};
