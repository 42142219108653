import { isUndefined, each } from 'lodash';
import GoogleMaps from './index';

const elements = [];

export default class GoogleMapsAutocomplete {
	static configureAutocomplete(element, onPlaceChange, key) {
		elements.push(element);
		window.gm_authFailure = () => {
			each(elements, (theElement) => {
				this.clearAutoComplete(theElement);
			});
		};

		const config = key ? { key } : {};

		return GoogleMaps.getInstance(config).loaded.then((theGoogs) => {
			const autocomplete = new theGoogs.maps.places.Autocomplete(element, {
				types: ['geocode'],
				componentRestrictions: {
					country: 'us'
				}
			});

			theGoogs.maps.event.addListener(autocomplete, 'place_changed', () => {
				const place = autocomplete.getPlace();
				// place_changed is triggered when user presses enter (even though autocomplete wasn't used)
				// To prevent this from creating a change event, we can check for a place_id which won't exist
				// if enter was pressed.
				if (!isUndefined(place.place_id)) {
					const { value } = element;
					onPlaceChange(value, autocomplete.getPlace());
				}
			});

			return autocomplete;
		});
	}

	static removeAutocomplete(autoComplete) {
		GoogleMaps.getInstance().loaded.then((theGoogs) => {
			theGoogs.maps.event.clearInstanceListeners(autoComplete);
		});
	}

	static clearAutoComplete(autoComplete) {
		this.removeAutocomplete(autoComplete);
		autoComplete.removeAttribute('placeholder'); //	oops something went wrong
		autoComplete.removeAttribute('disabled'); //	user can type
		autoComplete.removeAttribute('style'); //	little warning icon
	}
}
