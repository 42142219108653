import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { PageTypes } from '@sparefoot/segment-react';

import { withSegmentPageViewTracking } from 'components/core/library/segment';
import { Page } from 'components/core/Page';

import { HomePageHero } from 'components/landing/HomePageHero';
import { HowItWorksSection } from 'components/brand/HowItWorksSection';
import { StorageTypesSection } from 'components/brand/StorageTypesSection';
import { SizeGuideSection } from 'components/brand/SizeGuideSection';
import { ReviewSection } from 'components/brand/ReviewSection';
import { FeaturedCitiesSection } from 'components/brand/FeaturedCitiesSection';

import './homepage.scss';

export const enhance = compose(
	connect(({ content }) => ({
		featuredCities: content.featuredCities
	})),
	withSegmentPageViewTracking(PageTypes.HOME)
);

export function HomePage({ featuredCities }) {
	return (
		<Page
			className="homepage"
			pageType={PageTypes.HOME}
			landingPage
		>
			<Helmet>
				<meta
					name="msvalidate.01"
					content="72430AA401040F456AF99A586F12B284"
				/>
				<script type="application/ld+json">
					{`
						{
							"@context": "http://schema.org",
							"@type": "WebSite",
							"url": "https://www.sparefoot.com",
							"potentialAction": {
								"@type": "SearchAction",
								"target": "https://www.sparefoot.com/search?location={search_term_string}",
								"query-input": "required name=search_term_string"
							}
						}
					`}
				</script>
				<script type="application/ld+json">
					{`
						{
							"name": "Sparefoot",
							"@context": "http://schema.org",
							"@type": ["Organization", "OnlineBusiness"],
							"url": "https://www.sparefoot.com",
							"logo": "https://www.sparefoot.com/logo.svg",
							"sameAs": [
								"https://twitter.com/SpareFoot",
								"https://www.facebook.com/SpareFoot",
								"https://www.instagram.com/sparefoot/",
								"https://www.pinterest.com/sparefoot/"
							],
							"contactPoint": [
								{
								"@type": "ContactPoint",
								"telephone": "+1-512-705-6208",
								"contactType": "customer service"
								}
							],
							"founder": "Chuck Gordon",
							"foundingDate": 2008
						}
					`}
				</script>
			</Helmet>
			<HomePageHero segmentCategory="homepage hero" />
			<HowItWorksSection />
			<SizeGuideSection />
			<StorageTypesSection />
			<ReviewSection />
			{!isEmpty(featuredCities) &&
				<FeaturedCitiesSection
					cities={featuredCities}
					fillOrder="vertical"
				/>
			}
		</Page>
	);
}

HomePage.propTypes = {
	featuredCities: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default enhance(HomePage);
