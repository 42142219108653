import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import CSSTransition from 'react-transition-group/CSSTransition';
import withShowHide from '@sparefoot/react-core/decorators/withShowHide';

import { Button } from 'components/core/Button';

import { on, off } from 'utils/dom/dom';
import { isLarge } from 'utils/breakpoints';

import './TooltipWithClickOutside.scss';

export const enhance = withShowHide('tooltip');

export class TooltipWithClickOutside extends PureComponent {
	constructor(props) {
		super(props);
		this.tooltipRef = React.createRef();
		this.handleClickOutside = this.handleClickOutside.bind(this);
		this.handleClick = this.handleClick.bind(this);
	}

	componentDidMount() {
		on('mousedown', this.handleClickOutside);
	}

	componentWillUnmount() {
		off('mousedown', this.handleClickOutside);
	}

	handleClick() {
		if (!isLarge(window.innerWidth)) {
			this.props.onShowTooltip();
		}
	}

	handleClickOutside(evt) {
		if (this.tooltipRef.current && !this.tooltipRef.current.contains(evt.target)) {
			this.props.onHideTooltip();
		}
	}

	render() {
		const {
			children,
			content,
			tooltipShowing,
			segmentLabel
		} = this.props;

		return (
			<div className="tooltip-with-click-outside">
				<Button
					className="tooltip-trigger"
					segmentLabel={`tooltip ${segmentLabel}`}
					onClick={this.handleClick}
					primary={false}
				>
					{children}
				</Button>
				<CSSTransition
					in={tooltipShowing}
					timeout={300}
					classNames="transition-fade-in"
					unmountOnExit
				>
					<div ref={this.tooltipRef} className="tooltip-content hide-on-desktop">
						{content}
						<div className="pointer" />
					</div>
				</CSSTransition>
			</div>
		);
	}
}

TooltipWithClickOutside.propTypes = {
	children: PropTypes.node.isRequired,
	content: PropTypes.node.isRequired,
	onHideTooltip: PropTypes.func,
	onShowTooltip: PropTypes.func,
	segmentLabel: PropTypes.string.isRequired,
	tooltipShowing: PropTypes.bool
};

export default enhance(TooltipWithClickOutside);
