import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { on, off, getBoundingClientRect } from 'utils/dom/dom';

const TRIGGER_EVENTS = ['resize', 'scroll', 'load'];

export default class StickyContainer extends PureComponent {
	constructor(props) {
		super(props);
		this.bindDOMRef = this.bindDOMRef.bind(this);
		this.getDistanceFromTop = this.getDistanceFromTop.bind(this);
		this.recomputeState = this.recomputeState.bind(this);
		this.triggerId = props.triggerId;
	}

	state = {
		sticky: false
	}

	componentDidMount() {
		on(TRIGGER_EVENTS, this.recomputeState);
		this.recomputeState();
	}

	componentWillUnmount() {
		off(TRIGGER_EVENTS, this.recomputeState);
	}

	getDistanceFromTop() {
		return Math.round(getBoundingClientRect(this.instance).top);
	}

	recomputeState() {
		const scrollTop = Math.min(0, window.pageYOffset);
		const fromTop = this.triggerId ?
			document.getElementById(this.triggerId).getBoundingClientRect().top + scrollTop :
			this.getDistanceFromTop() + scrollTop;
		const sticky = fromTop <= 0;

		if (sticky !== this.state.sticky) {
			this.setState({
				sticky
			});
		}
	}

	bindDOMRef(ref) {
		this.instance = ref;
	}

	render() {
		const { sticky } = this.state;

		return (
			<div
				ref={this.bindDOMRef}
				className={classnames('sticky-container', { sticky })}
			>
				{this.props.children}
			</div>
		);
	}
}

StickyContainer.propTypes = {
	children: PropTypes.node,
	triggerId: PropTypes.string
};
