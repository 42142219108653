import React from 'react';
import PropTypes from 'prop-types';

import { withSegmentContext } from 'components/core/library/segment';
import { Image } from 'components/core/Image';
import { H2, H3, Paragraph } from 'components/core/Typography';
import { Button } from 'components/core/Button';

import boxes from 'assets/images/homepage/boxes.svg';
import rv from 'assets/images/homepage/camper.svg';
import boat from 'assets/images/homepage/boat.svg';
import car from 'assets/images/homepage/car.svg';

import './StorageTypesSection.scss';

const typesOfSelfStorage = [
	{
		title: 'Self-Storage',
		// eslint-disable-next-line max-len
		description: 'Moving, have a growing family, or looking to store your valuable items for one of many reasons? Nothing is more flexible, affordable, and safe than renting storage units.',
		url: '/storage.html',
		segmentLabel: 'link self-storage',
		image: boxes
	},
	{
		title: 'RV Storage',
		// eslint-disable-next-line max-len
		description: 'Don’t have the space to keep your RV or camper parked at your home? Find and reserve RV, Trailer, camper, and other large vehicle storage near you.',
		url: '/rv-storage.html',
		segmentLabel: 'link rv',
		image: rv
	},
	{
		title: 'Boat Storage',
		// eslint-disable-next-line max-len
		description: 'Looking for a safe and secure space to keep your boat? SpareFoot can connect you with facilities offering indoor, outdoor, and other boat storage options.',
		url: '/boat-storage.html',
		segmentLabel: 'link boat',
		image: boat
	},
	{
		title: 'Car Storage',
		// eslint-disable-next-line max-len
		description: 'Need a safe place to store that collector, vintage, classic, or spare car? Search thousands of  covered, uncovered, inside, outside, and enclosed vehicle storage units.',
		url: '/car-storage.html',
		segmentLabel: 'link car-storage',
		image: car
	}
];

export function ProductCard({ title, description, url, segmentLabel, imageSrc }) {
	return (
		<div className="product-card">
			<Image src={imageSrc} alt={title} lazyLoad={true} width="72" height="72" />
			<div className="card-content">
				<div>
					<H3 size="medium" weight="ultraBold" className="card-title">{title}</H3>
					<Paragraph size="smaller" weight="standard" className="card-description">{description}</Paragraph>
				</div>
				<Button
					segmentLabel={segmentLabel}
					href={url}
				>
					Read More
				</Button>
			</div>
		</div>
	);
}

export function StorageTypesSection() {
	return (
		<div className="storage-types-section">
			<div className="section-content">
				<H2 family="rounded" size="semiLarge" className="section-title">
					Types of Self-Storage
				</H2>
				<Paragraph size="small" weight="standard" className="section-description">
					Store your items the right way by finding self-storage that meets your storage needs.
				</Paragraph>
				<ul className="types-list">
					{typesOfSelfStorage.map((item) => (
						<li className="type-item" key={item.title}>
							<ProductCard
								title={item.title}
								description={item.description}
								url={item.url}
								segmentLabel={item.segmentLabel}
								imageSrc={item.image}
							/>
						</li>
					))}
				</ul>
			</div>
		</div>
	);
}

ProductCard.propTypes = {
	title: PropTypes.string,
	description: PropTypes.string,
	url: PropTypes.string,
	segmentLabel: PropTypes.string,
	imageSrc: PropTypes.string
};

export default withSegmentContext(StorageTypesSection);
