import React from 'react';
import PropTypes from 'prop-types';
import { compose, withHandlers, withProps, withStateHandlers } from 'recompose';
import classnames from 'classnames';
import CSSTransition from 'react-transition-group/CSSTransition';
import withShowHide from '@sparefoot/react-core/decorators/withShowHide';
import { withClickOutside } from 'decorators';

import { Button } from 'components/core/Button';

import { isLarge } from 'utils/breakpoints';

import './Tooltip.scss';

export const enhance = compose(
	withStateHandlers(
		{ timer: null },
		{ setTimer: () => (newTimer) => ({ timer: newTimer }) }
	),
	withShowHide('tooltip'),
	withHandlers({
		clickOnMobile:
			({ onToggleTooltip }) => (evt) => {
				if (!isLarge(window.innerWidth)) {
					onToggleTooltip();
					evt.preventDefault();
					evt.stopPropagation();
				}
			},
		onFocus:
			({ onShowTooltip, hideTooltipForLarge, timer, setTimer }) => () => {
				if (isLarge(window.innerWidth) && !hideTooltipForLarge) {
					if (timer) {
						clearTimeout(timer);
						setTimer(null);
					}
					onShowTooltip();
				}
			},
		onBlur:
			({ onHideTooltip, clickToClose, setTimer, closeDelayHover }) => () => {
				if (isLarge(window.innerWidth) && !clickToClose) {
					if (closeDelayHover) {
						const newTimer = setTimeout(() => {
							onHideTooltip();
						}, 500);
						setTimer(newTimer);
					} else {
						onHideTooltip();
					}
				}
			},
		onFocusTooltipBox:
			({ timer, setTimer }) => () => {
				if (timer) {
					clearTimeout(timer);
					setTimer(null);
				}
			},
		onBlurTooltipBox:
			({ onHideTooltip }) => () => {
				onHideTooltip();
			}
	}),
	withProps(({ onHideTooltip, tooltipShowing }) => ({
		outsideClickHandler: () => {
			onHideTooltip();
		},
		outsideParent: '.tooltip',
		outsideCondition: tooltipShowing
	})),
	withClickOutside
);

export function Tooltip({
	children,
	className,
	content,
	segmentLabel,
	onFocus,
	onBlur,
	size,
	iconRightSide,
	buttonTooltip,
	tooltipShowing,
	tooltipIcon,
	clickOnMobile,
	plainText,
	fillParentContainer,
	simpleButton,
	backgroundColor,
	onFocusTooltipBox,
	onBlurTooltipBox,
	as = 'button'
}) {
	const Component = as === 'div' ? 'div' : Button;

	return (
		<div
			className={classnames('tooltip', className, {
				'plain-text': plainText,
				'fill-container': fillParentContainer
			})}
		>
			<Component
				className={classnames(
					'tooltip-trigger',
					fillParentContainer,
					{
						'simple-tooltip': simpleButton,
						'on-tooltip-showing': tooltipShowing
					}
				)}
				segmentLabel={`tooltip ${segmentLabel}`}
				onMouseEnter={onFocus}
				onMouseLeave={onBlur}
				onClick={clickOnMobile}
				link={!buttonTooltip}
			>
				{!iconRightSide && tooltipIcon}
				{children}
				{iconRightSide && tooltipIcon}
			</Component>

			<CSSTransition
				in={tooltipShowing}
				timeout={300}
				classNames="transition-fade-in"
				unmountOnExit
			>
				<div
					onMouseEnter={onFocusTooltipBox}
					onMouseLeave={onBlurTooltipBox}
					className={classnames(
						'tooltip-content',
						size,
						backgroundColor
					)}
				>
					{content}
					<div className="pointer" />
				</div>
			</CSSTransition>
		</div>
	);
}

Tooltip.propTypes = {
	content: PropTypes.node.isRequired,
	className: PropTypes.string,
	children: PropTypes.node.isRequired,
	onFocus: PropTypes.func,
	onBlur: PropTypes.func,
	segmentLabel: PropTypes.string.isRequired,
	tooltipIcon: PropTypes.node,
	tooltipShowing: PropTypes.bool,
	buttonTooltip: PropTypes.bool,
	clickOnMobile: PropTypes.func,
	plainText: PropTypes.bool,
	iconRightSide: PropTypes.bool,
	size: PropTypes.oneOf(['medium', 'large']),
	fillParentContainer: PropTypes.oneOf(['left', 'center', 'right']), // Text alignment when enabled
	simpleButton: PropTypes.bool,
	backgroundColor: PropTypes.oneOf(['white', 'bg-white']),
	onFocusTooltipBox: PropTypes.func,
	onBlurTooltipBox: PropTypes.func,
	as: PropTypes.oneOf(['button', 'div']) // New prop to dynamically render component
};

export default enhance(Tooltip);
