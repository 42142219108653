export const LIFE_TO_EXR_FACILITIES = [
	229787,
	229788,
	229789,
	229790,
	229791,
	229792,
	229793,
	229794,
	229795,
	229796,
	229797,
	229798,
	229799,
	229800,
	229801,
	229802,
	229803,
	229804,
	229805,
	229806,
	229807,
	229808,
	229809,
	229810,
	229811,
	229813,
	229816,
	229817,
	229818,
	229819,
	229821,
	229822,
	229823,
	229824,
	229826,
	229827,
	229828,
	229829,
	229830,
	229831,
	229832,
	229833,
	229834,
	229835,
	229836,
	229837,
	229838,
	229839,
	229840,
	229841,
	229842,
	229843,
	229844,
	229845,
	229846,
	229847,
	229849,
	229850,
	229851,
	229852,
	229853,
	229854,
	229864,
	229865,
	229866,
	229867,
	229868,
	229869,
	229870,
	229871,
	229872,
	229873,
	229874,
	229875,
	229876,
	229877,
	229878,
	229879,
	229881,
	229883,
	229884,
	229885,
	229886,
	229887,
	229888,
	229889,
	229890,
	229891,
	229892,
	229893,
	229894,
	229895,
	229896,
	229897,
	229898,
	229899,
	229900,
	229901,
	229902,
	229903,
	229904,
	229905,
	229906,
	229907,
	229908,
	229909,
	229910,
	229911,
	229912,
	229913,
	229914,
	229915,
	229917,
	229918,
	229919,
	229920,
	229921,
	229922,
	229923,
	229924,
	229925,
	229926,
	229927,
	229928,
	229929,
	229930,
	229931,
	229932,
	229933,
	229934,
	229935,
	229936,
	229937,
	229938,
	229939,
	229940,
	229941,
	229942,
	229943,
	229944,
	229945,
	229946,
	229947,
	229948,
	229949,
	229950,
	229951,
	229952,
	229953,
	229955,
	229956,
	229958,
	229959,
	229960,
	229961,
	229962,
	229963,
	229964,
	229965,
	229966,
	229968,
	229969,
	229970,
	229972,
	229973,
	229974,
	229975,
	229976,
	229977,
	229978,
	229979,
	229980,
	229981,
	229982,
	229983,
	229984,
	229985,
	229986,
	229987,
	229988,
	229989,
	229990,
	229991,
	229992,
	229993,
	229995,
	229996,
	229997,
	229998,
	229999,
	230000,
	230001,
	230002,
	230003,
	230004,
	230005,
	230006,
	230007,
	230008,
	230009,
	230010,
	230011,
	230012,
	230013,
	230014,
	230015,
	230016,
	230017,
	230018,
	230019,
	230021,
	230022,
	230023,
	230024,
	230025,
	230026,
	230027,
	230028,
	230029,
	230032,
	230034,
	230035,
	230036,
	230037,
	230038,
	230039,
	230040,
	230041,
	230042,
	230043,
	230044,
	230045,
	230046,
	230091,
	230095,
	230096,
	230098,
	230100,
	230101,
	230103,
	230104,
	230108,
	230110,
	230113,
	230116,
	230117,
	230118,
	230120,
	230121,
	230122,
	230123,
	230124,
	230125,
	230127,
	230128,
	230129,
	230130,
	230131,
	230135,
	230138,
	230139,
	230140,
	230141,
	230142,
	230143,
	230144,
	230147,
	230149,
	230150,
	230151,
	230152,
	230153,
	230154,
	230155,
	230159,
	230161,
	230165,
	230170,
	230172,
	230178,
	230195,
	230204,
	230250,
	230252,
	230254,
	230256,
	230259,
	230262,
	230271,
	230273,
	230280,
	230286,
	230289,
	230295,
	230299,
	230303,
	230306,
	230310,
	230312,
	230313,
	230316,
	230319,
	230322,
	230328,
	230334,
	230341,
	230343,
	230345,
	230347,
	230350,
	230352,
	230354,
	230357,
	230359,
	230361,
	230367,
	230368,
	230372,
	230374,
	230375,
	230396,
	230398,
	230404,
	230406,
	230409,
	230410,
	230412,
	230414,
	230416,
	230417,
	230426,
	230427,
	230429,
	230433,
	230434,
	230436,
	230437,
	230439,
	230440,
	230442,
	230443,
	230445,
	230446,
	230447,
	230448,
	230454,
	230456,
	230457,
	230458,
	230464,
	230468,
	230472,
	230473,
	230474,
	230475,
	230477,
	230479,
	230480,
	230481,
	230486,
	230488,
	230490,
	230493,
	230494,
	230496,
	230499,
	230508,
	230510,
	230517,
	230518,
	230520,
	230522,
	230523,
	230524,
	230525,
	230526,
	230527,
	230528,
	230529,
	230531,
	230532,
	230534,
	230536,
	230539,
	230541,
	230542,
	230544,
	230545,
	230546,
	230548,
	230549,
	230551,
	230553,
	230555,
	230557,
	230559,
	230561,
	230563,
	230565,
	230567,
	230568,
	230569,
	230571,
	230572,
	230573,
	230575,
	230580,
	230582,
	230584,
	230589,
	230591,
	230594,
	230598,
	230600,
	230602,
	230604,
	230606,
	230608,
	230610,
	230614,
	230617,
	230619,
	230620,
	230624,
	230625,
	230627,
	230631,
	230632,
	230633,
	230634,
	230636,
	230637,
	230639,
	230641,
	230644,
	230647,
	230649,
	230651,
	230653,
	230656,
	230660,
	230663,
	230665,
	230668,
	230669,
	230670,
	230672,
	230676,
	230678,
	230681,
	230682,
	230685,
	230686,
	230687,
	230688,
	230690,
	230693,
	230695,
	230697,
	230698,
	230700,
	230703,
	230707,
	230709,
	230711,
	230713,
	230716,
	230718,
	231248,
	231250,
	231252,
	231253,
	231254,
	231255,
	231256,
	231258,
	231259,
	231260,
	231261,
	231262,
	231263,
	231264,
	231265,
	231266,
	231267,
	231269,
	231270,
	231271,
	231272,
	231273,
	231274,
	231275,
	231276,
	231277,
	231278,
	231279,
	231280,
	231281,
	231282,
	231283,
	231284,
	231286,
	231287,
	231288,
	231289,
	231290,
	231291,
	231292,
	231293,
	231294,
	231295,
	231296,
	231297,
	231298,
	231299,
	231300,
	231301,
	231302,
	231303,
	231304,
	231305,
	231306,
	231307,
	231308,
	231309,
	231310,
	231311,
	231312,
	231313,
	231314,
	231315,
	231316,
	231317,
	231318,
	231319,
	231320,
	231322,
	231323,
	231324,
	231325,
	231326,
	231327,
	231328,
	231329,
	231330,
	231331,
	231332,
	231334,
	231335,
	231336,
	231337,
	231338,
	231339,
	231340,
	231341,
	231342,
	231343,
	231344,
	231345,
	231346,
	231347,
	231348,
	231349,
	231350,
	231351,
	231352,
	231353,
	231354,
	231355,
	231356,
	231357,
	231358,
	231359,
	231360,
	231361,
	231362,
	231363,
	231364,
	231365,
	231366,
	231367,
	231368,
	231369,
	231370,
	231371,
	231372,
	231373,
	231374,
	231375,
	231376,
	231377,
	231378,
	231379,
	231380,
	231381,
	231382,
	231383,
	231384,
	231385,
	231386,
	231387,
	231388,
	231389,
	231390,
	231391,
	231392,
	231393,
	231394,
	231395,
	231396,
	231397,
	231398,
	231399,
	231400,
	231404,
	231405,
	231406,
	231407,
	231408,
	231409,
	231410,
	231411,
	231412,
	231413,
	231414,
	231415,
	231416,
	231417,
	231419,
	231420,
	231423,
	231424,
	231425,
	231426,
	231455,
	231456,
	231457,
	231458,
	231459,
	231460,
	231461,
	231462,
	231463,
	231464,
	231465,
	231466,
	231467,
	231468,
	231469,
	231470,
	231471,
	231472,
	231473,
	231476,
	231477,
	231478,
	231479,
	231480,
	231481,
	231482,
	231483,
	231484,
	231485,
	231486,
	231487,
	231488,
	231489,
	231490,
	231491,
	231492,
	231493,
	231494,
	231495,
	231497,
	231498,
	231499,
	231500,
	231501,
	231502,
	231503,
	231504,
	231505,
	231506,
	231507,
	231508,
	231509,
	231510,
	231511,
	231512,
	231513,
	231514,
	231515,
	231516,
	231517,
	231518,
	231520,
	231521,
	231522,
	231523,
	231524,
	231525,
	231526,
	231542,
	231543,
	231544,
	231545,
	231546,
	231547,
	231548,
	231549,
	231550,
	231551,
	231552,
	231553,
	231554,
	231557,
	231558,
	231559,
	231560,
	231561,
	231562,
	231563,
	231564,
	231565,
	231566,
	231567,
	231568,
	231569,
	231570,
	231571,
	231572,
	231573,
	231574,
	231575,
	231576,
	231577,
	231578,
	231579,
	231580,
	231581,
	231582,
	231585,
	231586,
	231587,
	231588,
	231589,
	231590,
	231591,
	231592,
	231593,
	231594,
	231595,
	231596,
	231597,
	231598,
	231599,
	231600,
	231601,
	231602,
	231603,
	231604,
	231605,
	231606,
	231607,
	231611,
	231612,
	231613,
	231614,
	231615,
	231616,
	231617,
	231618,
	231619,
	231620,
	231621,
	231622,
	231623,
	231624,
	231625,
	231626,
	231627,
	231628,
	231629,
	231630,
	231634,
	231635,
	231636,
	231637,
	231638,
	231639,
	231640,
	231641,
	231642,
	231646,
	231647,
	231648,
	231649,
	231651,
	231653,
	231654,
	231655,
	231657,
	231658,
	231659,
	231660,
	231661,
	231662,
	231663,
	231664,
	231667,
	231668,
	231669,
	231670,
	231671,
	231672,
	231673,
	231674,
	231675,
	231676,
	231680,
	231681,
	231682,
	231683,
	231684,
	231685,
	231686,
	231687,
	231688,
	231689,
	231690,
	231691,
	231692,
	231693,
	231695,
	231696,
	231697,
	231699,
	231700,
	231701,
	231702,
	231703,
	231704,
	231705,
	231706,
	231707,
	231708,
	231709,
	231710,
	231711,
	231712,
	231713,
	231715,
	231716,
	231717,
	231718,
	231719,
	231720,
	231721,
	231722,
	231723,
	231724,
	231726,
	231727,
	231729,
	231730,
	231731,
	231732,
	231733,
	231734,
	231737,
	231738,
	231739,
	231740,
	231741,
	231742,
	231744,
	231745,
	231747,
	231748,
	231749,
	231750,
	231751,
	231752,
	231753,
	231754,
	231755,
	231756,
	231757,
	231758,
	231759,
	231474
];

export const STORAGE_EXPRESS_ES_FACILITIES = [
	201283,
	206205,
	217117,
	228316,
	228317,
	228318,
	228319,
	228320,
	228321,
	228322,
	228323,
	228324,
	228325,
	228326,
	228327,
	228328,
	228329,
	228330,
	228331,
	228332,
	228333,
	228334,
	228335,
	228336,
	228337,
	228338,
	228339,
	228340,
	228341,
	228342,
	229461,
	229462,
	229463,
	229464,
	229465,
	229466,
	229467,
	229468,
	229469,
	229470,
	229471,
	229472,
	229473,
	229474,
	229475,
	233221,
	233222,
	233223,
	233224,
	233225,
	233226,
	233227,
	233228,
	233229,
	233230,
	233231,
	233232,
	233233,
	233234,
	233235,
	233236,
	233237,
	233238,
	233239,
	233240,
	233241,
	233242,
	233243,
	233244,
	233245,
	233246,
	233247,
	233248,
	233249,
	233250,
	233251,
	233252,
	233253,
	233254,
	233255,
	233256,
	233257,
	233258,
	233259,
	233260,
	233261,
	233262,
	233263,
	233264,
	233265,
	233266,
	233267,
	233268,
	233269,
	233270,
	233271,
	233272,
	233273,
	233274,
	233275,
	233276,
	233277,
	233278,
	233279,
	233280,
	233281,
	233282,
	233283,
	233284,
	233285,
	233288,
	233294,
	233297,
	233298,
	233793
];
