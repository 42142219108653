import React from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import range from 'lodash/range';
import classnames from 'classnames';

import { Star } from 'components/svg';
import { getRoundedStarRating } from 'utils/rating';

import './stars.scss';

/**
 * Renders a series of stars to represent the rating/outOf values
 */
export function Stars({
	className, outOf, rating, size, color
}) {
	return (
		<span className={classnames(className, 'stars')}>
			{map(range(0, outOf), (starValue) => (
				<Star
					key={starValue}
					value={getRoundedStarRating(rating - starValue)}
					size={size}
					color={color}
				/>
			))}
		</span>
	);
}

Stars.propTypes = {
	className: PropTypes.string,
	/** The rating to show */
	rating: PropTypes.number,
	/** What the rating is out of */
	outOf: PropTypes.number,
	/** Size of the stars to render */
	size: PropTypes.oneOf(['tiny', 'smaller', 'small', 'medium', 'large']),
	color: PropTypes.oneOf(['gray', 'yellow'])
};

Stars.defaultProps = {
	rating: 0,
	outOf: 5,
	size: 'tiny',
	color: 'yellow'
};

export default Stars;
