import React from 'react';
import PropTypes from 'prop-types';
import withHeadlineProps from './headlineProps';
import './headline.scss';

/**
 * H1 text component
 */
export function H1(props) {
	return (
		<h1 className={props.className}>
			{props.children}
		</h1>
	);
}

H1.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node
};


export default withHeadlineProps(H1);
