import React from 'react';
import PropTypes from 'prop-types';

import { withSegmentSelectTracking } from 'components/core/library/segment';
import { Text } from 'components/core/Typography';

export function SelectOption({
	id, checked, className, disabled, display, onChange, type, value, name
}) {
	return (
		<label htmlFor={id} className="option-group-option">
			<input
				className={className}
				id={id}
				disabled={disabled}
				name={name}
				checked={checked}
				onChange={onChange}
				value={value}
				type={type}
			/>
			<Text
				className="option-text"
				size="small"
				last
			>
				{display}
			</Text>
		</label>
	);
}

SelectOption.propTypes = {
	className: PropTypes.string,
	name: PropTypes.string.isRequired,
	value: PropTypes.string.isRequired,
	checked: PropTypes.bool.isRequired,
	onChange: PropTypes.func.isRequired,
	disabled: PropTypes.bool,
	id: PropTypes.string.isRequired,
	type: PropTypes.oneOf(['radio', 'checkbox']).isRequired,
	display: PropTypes.node.isRequired
};

export default withSegmentSelectTracking(SelectOption);
