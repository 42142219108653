
import React from 'react';
import PropTypes from 'prop-types';
import isNil from 'lodash/isNil';
import compose from 'recompose/compose';
import withStateHandlers from 'recompose/withStateHandlers';
import withProps from 'recompose/withProps';
import { filter, includes } from 'lodash';

import { withSegmentContext } from 'components/core/library/segment';
import SelectOption from './SelectOption';

import './selectOptionsGroup.scss';

export const enhance = compose(
	withSegmentContext,
	withStateHandlers(
		(props) => {
			/* eslint-disable prefer-destructuring */
			let selected;
			if (!isNil(props.selected)) {
				selected = props.selected;
			} else {
				selected = props.groupType === 'single' ? null : [];
			}

			return { selected };
			/* eslint-enable */
		},
		{
			handleSelect: (state, props) => (checked, value) => {
				let selected;

				if (props.groupType === 'single') {
					selected = value;
				} else if (checked) {
					selected = [...state.selected, value];
				} else {
					selected = filter(state.selected, (v) => v !== value);
				}

				props.onChange(selected);
				return { selected };
			}
		}
	),
	withProps((props) => ({
		handleChange: (event) => {
			const { checked, value } = event.target;
			props.handleSelect(checked, value);
		},
		options: props.options.map((option) => {
			let selected;

			if (props.groupType === 'single') {
				selected = props.selected === option.value;
			} else {
				selected = includes(props.selected, option.value);
			}
			return {
				...option,
				selected,
				id: `${props.name}-${option.value}`
			};
		})
	}))
);


export function SelectOptionsGroup({
	handleChange, options, groupType, name
}) {
	return (
		<div className="select-options-group">
			{options.map((option) => (
				<SelectOption
					name={name}
					key={option.id}
					display={option.display}
					className="option-group-input"
					value={option.value}
					checked={option.selected}
					onChange={handleChange}
					disabled={option.disabled}
					id={option.id}
					type={groupType === 'single' ? 'radio' : 'checkbox'}
					segmentLabel={option.value || option.display}
				/>
			))}
		</div>
	);
}

SelectOptionsGroup.propTypes = {
	/** Options to display */
	options: PropTypes.arrayOf(PropTypes.shape({
		value: PropTypes.any,
		display: PropTypes.any,
		disabled: PropTypes.bool
	})).isRequired,
	/** The name of the set of options */
	name: PropTypes.string.isRequired,
	/** The type of group, either single select of multi select */
	groupType: PropTypes.oneOf(['single', 'multiple']),
	handleChange: PropTypes.func
};

SelectOptionsGroup.defaultProps = {
	groupType: 'single'
};

export default enhance(SelectOptionsGroup);
