import { compose, defaultProps, withProps } from 'recompose';
import classnames from 'classnames';

export default compose(
	defaultProps({
		rounded: false,
		light: false,
		centered: false,
		strong: false,
		size: 'medium'
	}),
	withProps((props) => ({
		className: classnames(
			'ph-headline',
			props.className,
			{
				rounded: props.rounded,
				light: props.light,
				centered: props.centered,
				strong: props.strong
			},
			props.size
		)
	}))
);
