import React, { Children } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { withSegmentContext } from 'components/core/library/segment';
import { Link } from 'components/core/Link';
import { Section, Group, Container } from 'components/core/Page';
import { H2, Paragraph } from 'components/core/Typography';

import './cityLandingPages.scss';

export function CityLandingPages({
	headline, subheading, cities, storageType, fillOrder, className
}) {
	return (
		<Section className={classnames('section-container city-landing-pages', fillOrder, className)}>
			<Container>
				<Group>
					{/* adding this className in the H2 for specific styling on the Homepage */}
					<H2 size="large" className="clps-list-headline">
						{headline}
					</H2>
					{!!subheading &&
						<Paragraph>
							{subheading}
						</Paragraph>
					}
				</Group>
				<ul className="city-list">
					{Children.toArray(cities.map((city) => (
						<li>
							<Link href={city.url} segmentLabel="city link">
								{`${city.name || city.city}${storageType ? ` ${storageType}` : ''}`}
							</Link>
						</li>
					)))}
				</ul>
			</Container>
		</Section>
	);
}

CityLandingPages.propTypes = {
	cities: PropTypes.arrayOf(
		PropTypes.shape({
			url: PropTypes.string,
			name: PropTypes.string,
			city: PropTypes.string
		}),
	),
	headline: PropTypes.string,
	subheading: PropTypes.string,
	storageType: PropTypes.string,
	fillOrder: PropTypes.oneOf([
		'horizontal',
		'vertical'
	]),
	className: PropTypes.string
};

CityLandingPages.defaultProps = {
	cities: [],
	fillOrder: 'horizontal'
};

export default withSegmentContext(CityLandingPages);
