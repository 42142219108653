import { vehicleSqftToSizeBucket, unitSqftToSizeBucket } from 'config/units';

export function getSizeBucketFromSearchTypeSqft(searchType, sqft) {
	if (searchType === 'vehicle') {
		return vehicleSqftToSizeBucket[sqft];
	}

	return unitSqftToSizeBucket[sqft];
}

export default getSizeBucketFromSearchTypeSqft;
