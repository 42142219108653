import React from 'react';
import PropTypes from 'prop-types';
import format from 'date-fns/format';

import { Text } from 'components/core/Typography';
import { Stars } from 'components/review/Stars';

import './ReviewRating.scss';

export default function ReviewRating({ reviewDate, reviewRating, reviewRatingOutOf, size = 'small' }) {
	return (
		<div className="review-rating">
			<Stars
				size={size}
				rating={reviewRating}
				outOf={reviewRatingOutOf}
			/>
			{ reviewDate &&
				<Text className="review-date">
					{format(reviewDate, 'MMMM Do, YYYY')}
				</Text>
			}
		</div>
	);
}

ReviewRating.propTypes = {
	reviewDate: PropTypes.instanceOf(Date),
	/** The rating of the review */
	reviewRating: PropTypes.number,
	/** What the rating is out of */
	reviewRatingOutOf: PropTypes.number,
	size: PropTypes.string
};
