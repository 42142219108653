import { isString } from 'lodash';

export function formatUSD(amount) {
	if (!amount) {
		return '$0.00';
	}
	return parseFloat(isString(amount) ? amount.replace(/,/g, '') : amount)
	.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
}

export function formatNumber(value = 0) {
	const number = isString(value) ? parseFloat(value.replace(/,/g, '')) : value;
	return number.toLocaleString('en-US');
}

export function getNumericalValue(value = 0) {
	const number = isString(value) ? parseInt(value.replace(/,/g, ''), 10) : value;
	return number;
}

export function separateStringFirstSpace(str) {
	const stringSpaceIndex = str.indexOf(' ');
	const stringBeforeSpace = str.substring(0, stringSpaceIndex);
	const stringAfterSpace = str.substring(stringSpaceIndex + 1);
	return [stringBeforeSpace, stringAfterSpace];
}

export function getInitials(name) {
	const nameParts = name.split(' ');
	const firstNameInitial = nameParts[0] ? nameParts[0][0] : '';
	const lastNameInitial = nameParts[1] ? nameParts[1][0] : '';

	if (!firstNameInitial && !lastNameInitial) {
		return '';
	}

	if (name.length === 2) {
		return name.substring(0, 2).toUpperCase();
	}

	return (
		(firstNameInitial.toUpperCase() || '') +
		(lastNameInitial.toUpperCase() || '')
	);
}
